:root {
   --background-color: #fafbfc;
   --text-color: #111;
   --main-background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./assets/backgrounds/main.jpg');
   --background-color-light: rgba(255, 255, 255, 0.9);
   --background-color-lighter: rgba(246, 246, 246, 0.2);
   --background-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

@media (prefers-color-scheme: dark) {
   :root {
      --background-color: #111;
      --text-color: #fff;
       --main-background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./assets/backgrounds/main-dark.jpg');
   --background-color-light:  rgba(0, 0, 0, 0.5);
   --background-color-lighter:  rgba(17, 17, 17, 0.2);
   --background-gradient: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
       
   }
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   font-family: 'Poppins', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   display: block;
}

::-webkit-scrollbar {
   width: 2px;
   height: 2px;
}

::-webkit-scrollbar-track {
   background: #111;
}

::-webkit-scrollbar-thumb {
   background: #fff;
   border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
   background: #eee;
   border-radius: 12px;
}

a:link,
a:visited {
   text-decoration: none;
   color: inherit;
}

a:hover {
   text-decoration: none;
   color: inherit;
}

a:active {
   text-decoration: none;
   color: inherit;
}

.progress-indicator.available {
   opacity: 0;
}

.progress-indicator {
   width: 100%;
   height: 100%;
   position: fixed;
   background-color: var(--background-color);
   opacity: 1;
   pointer-events: none;
   -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
   z-index: 9999;
}

.insp-logo-frame {
   background-color: var(--background-color);
   display: -webkit-flex;
   display: -moz-flex;
   display: flex;
   -webkit-flex-direction: column;
   -moz-flex-direction: column;
   flex-direction: column;
   -webkit-justify-content: center;
   -moz-justify-content: center;
   justify-content: center;
   -webkit-animation: fadein 436ms;
   -moz-animation: fadein 436ms;
   animation: fadein 436ms;
   height: 100%;
}

.insp-logo-frame-img {
   width: 200px;
   height: 200px;
   -webkit-align-self: center;
   -moz-align-self: center;
   align-self: center;
   border-radius: 50%;
}

.insp-logo-frame > h2 {
   font-family: 'Poppins', sans-serif;
   color: var(--text-color);
   font-weight: 400;
   font-size: 1rem;
   text-align: center;
   width: 40%;
   margin-inline: auto;
}

.progress-indicator-head {
   height: 5px;
   overflow: hidden;
   position: relative;
}

.progress-indicator .first-indicator,
.progress-indicator .second-indicator {
   background-color: var(--text-color);
   bottom: 0;
   left: 0;
   right: 0;
   top: 0;
   position: absolute;
   -webkit-transform-origin: left center;
   -moz-transform-origin: left center;
   transform-origin: left center;
   -webkit-transform: scaleX(0);
   -moz-transform: scaleX(0);
   transform: scaleX(0);
}

.progress-indicator .first-indicator {
   -webkit-animation: first-indicator 2s linear infinite;
   -moz-animation: first-indicator 2s linear infinite;
   animation: first-indicator 2s linear infinite;
}

.progress-indicator .second-indicator {
   -webkit-animation: second-indicator 2s linear infinite;
   -moz-animation: second-indicator 2s linear infinite;
   animation: second-indicator 2s linear infinite;
}

.progress-indicator .insp-logo {
   animation: App-logo-spin infinite 20s linear;
   border-radius: 50%;
   -webkit-align-self: center;
   -moz-align-self: center;
   align-self: center;
}

@-webkit-keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@-moz-keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes fadein {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

@keyframes first-indicator {
   0% {
      transform: translate(0) scaleX(0);
   }

   25% {
      transform: translate(0) scaleX(0.5);
   }

   50% {
      transform: translate(25%) scaleX(0.75);
   }

   75% {
      transform: translate(100%) scaleX(0);
   }

   100% {
      transform: translate(100%) scaleX(0);
   }
}

@keyframes second-indicator {
   0% {
      transform: translate(0) scaleX(0);
   }

   60% {
      transform: translate(0) scaleX(0);
   }

   80% {
      transform: translate(0) scaleX(0.6);
   }

   100% {
      transform: translate(100%) scaleX(0.1);
   }
}

@media only screen and (max-width: 720px) {
   .insp-logo-frame > h2 {
      font-size: 1rem;
      width: 80vw;
   }
}

